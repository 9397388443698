.comingsoon-container {
    width: 80%;
    margin: auto;
    max-width: 500px;
}

.comingsoon-card {
    padding: 30px 60px;
    border: 1px solid black;
    border-radius: 5px;
    margin-bottom: 24px;
}

.login-btn {
    margin-top: 20px;
}

.takeatour-container {
    margin-top: 20px;
}