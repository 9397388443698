.ftecard-container {
    width: 50%;
    min-width: 500px;
    max-width: 900px;
    background-color: white;
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.ftecard-card {
    padding: 30px 60px 40px 60px;
    border: 1px solid black;
    border-radius: 5px;
}

.ftecard-card h1 {
    margin-bottom: 15px;
}

.ftecard-card-form-control-field-checkbox{
    display: flex;
    justify-content: flex-start;
    align-items: bottom;
}