.days-indicator-container{
    margin-top: 10px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    grid-gap: 7px;
}

.day-indicator{
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border: 5px white solid;
}

.day-indicator-now {
    border: 5px #444 solid;
    background: #f4f4f4;
    color: black;
}

.day-indicator-past {
    border: 5px #f9f9f9 solid;
    background: #f9f9f9;
    color: #999;
}

.day-indicator-futur {
    border: 5px #f4f4f4 solid;
    background: #f4f4f4;
    color: black;
}

.day-indicator-label{
    margin: auto;
}