* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}

html, body, #root {
  height: 100%;
  width: 100%;
  margin: 0;
}

#main-container {
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 80%;
  padding: 30px;
}

.container{
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header{
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;
}

.header ul{
  display: flex;
  margin-right: 0;
  list-style: none;
}

.header ul{
  white-space: nowrap;
}

.headerlink{
  color:black;
  font-size: 18px;
  text-decoration:none;
  margin-left: 24px;
}


.headerseparator {
  margin: 5px auto 50px auto;
  background-color: #eee;
  width: 80%;
  height: 1px;
}

.footer{
  margin: 0px auto;
  margin-top: 24px;
  padding: 12px 0px;
}

.form-control{
  margin: auto;
  margin-top: 10px;
  margin-bottom: 20px
}

.form-control-field{
  display: flex;
  flex-direction: column;
}

.form-control-input{
  margin:5px;
  padding: 5px 10px;
  font-size: 16px;
}

.button {
  border: none;
  border-radius: 5px;
  outline: none;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 15px;
  background: black;
}

.button:hover {
  transform: scale(0.99);
}

.button-block {
  display: block;
  width: 99%;
  margin: auto;
  margin-top: 10px;
}

.button-sub-header1{
  font-size: 12px;
  padding: 0px 12px;
  margin-bottom: 19px;
  margin-left: 10px;
}

.button-sub-header2{
  font-size: 12px;
  padding: 0px 12px;
  margin-bottom: 8px;
  margin-left: 10px;
}

.img-center-cropped {
  width: 100px;
  height: 100px;
  background-position: center center;
  background-repeat: no-repeat;
}