.login-container {
    width: 80%;
    margin: auto;
    max-width: 500px;
}

.login-card {
    padding: 30px 60px;
    border: 1px solid black;
    border-radius: 5px;
    margin-bottom: 24px;
}

.login-error {
    border: 2px solid red;
    border-radius: 5px;
}

.login-btn {
    margin-top: 20px;
}

.login-footer-container {
    margin-top: 20px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
}

.takeatour-container {
    margin-top: 20px;
}

.separator {
    margin: 40px auto;
    background-color: #ccc;
    width: 80%;
    height: 1px;
}