.task-details-container {
  height: 100%;
  flex-grow: 1;
  margin-top: 20px;
}

.task-details-container h1 {
  font-size: 50px;
  margin-top: 10px;
}

.task-details-container h1:hover {
  background-color: #f9f9f9;
}

.task-details-title-textarea {
  color: #111;
  width: 100%;
  height: 80px;
  margin-top: 5px;
  font-size: 50px;
  resize: vertical;
}

.task-details-body {
  width: 100%;
  margin-top: 5px;
}

.task-details-body label {
  font-size: 18px;
}

.task-details-body-textarea {
  color: #111;
  width: 100%;
  height: auto;
  margin-top: 5px;
  resize: none;
  flex: 1;
  font-size: 18px;
}

.task-details-parameters-container{
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.task-details-number-control-input{
  width: 50px;
}