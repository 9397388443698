.settings-button{
    margin-bottom: 10px;
}

.statsdayoperation-container{
    margin-bottom: 40px;
}

.localdatabase-container{
    margin-bottom: 40px;
}