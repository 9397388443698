.quicktasking-line-input {
  background-color: #f9f9f9;
  border: 0px;
  width: 100%;
}

.quicktasking-line-input:hover {
  outline: 0px;
  background-color: #f4f4f4;
}

.quicktasking-line-input:focus{
    outline: 0px;
}

.quicktasking-line-container-recurrent{
    border-left: 5px solid yellowgreen;
}

.quicktasking-line-container-interruption{
    border-left: 5px solid red;
}

.quicktasking-line-container-discovery{
    border-left: 5px solid blue;
}

.quicktasking-line-container-practice{
    border-left: 5px solid orange;
}

.quicktasking-line-container-unique{
    border-left: 5px solid lightgrey;
}

.quicktasking-line-input:focus {
  outline: 0px;
}

.quicktasking-line-container {
  margin-top: 2px;
  padding: 5px 10px;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
}

.quicktasking-line-container:hover {
  background-color: #f4f4f4;
}

.quicktasking-line-container:hover > .quicktasking-controls {
  color: black;
}

.quicktasking-control-container:hover > .quicktasking-controls {
  color: black;
}

.quicktasking-controls {
  color: #f9f9f9;
  margin-left: 5px;
}

.quicktasking-controls {
  color: #f9f9f9;
  margin-left: 2px;
}

.quicktasking-control-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.quicktasking-control-container h6 {
  margin: 0px 0px;
  margin-left: 2px;
  padding-top: 2px;
  white-space: nowrap;
}

.fixed-width {
  width: 20px;
}