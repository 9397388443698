.timeline-container{
    display: flex;
    flex-direction: column;
}

.timeline{
    display: flex; 
    justify-content: space-between;
    align-items: center;
}
  
.timeline-hourlabel{
    color:#333;
    min-width: 40px;
    text-align: center;
}
  
.timeline-dash{
    height: 1.5px;
    background-color: #ccc;
    margin: 0px 5px 0px 5px;
    width: 100%;
}

.timeline-timeindicator{
    color: #aaa;
}