.button-nowview-subtimeline-info{
    margin-top: 40px;
    background-color: yellowgreen;
    flex-grow: 1;
}

.button-nowview-subtimeline-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.button-nowview-subtimeline-error{
    margin-top: 40px;
    background-color: darkred;
}

