.aboutthat-container {
    width: 80%;
    margin: auto;
    max-width: 600px;
}

.aboutthat-card {
    padding: 30px 60px;
    border: 1px solid black;
    border-radius: 5px;
}

.aboutthat-card h1{
    margin-bottom: 20px;
}

.aboutthat-card li{
    margin-left: 20px;
}

.aboutthat-buttons-container {
    display: flex;
    justify-content:center;
    margin-top: 30px;
    margin-bottom: 10px;
}

.aboutthat-buttons {
    margin: 5px;
}