.blueprintslist{
    margin: 10px 0px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 5px;
}

.blueprintslist-item{
    background-color: #f4f4f4;
    border: 2px solid #f4f4f4;
    padding: 10px 20px;
    cursor: pointer;
    overflow: hidden;
}

.blueprintslist-item:hover{
    border: 2px solid gray;
}