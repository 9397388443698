.timeblock-details-container {
  height: 100%;
  flex-grow: 1;
  margin-top: 20px;
}

.timeblock-details-header {
  display: flex;
  align-items: flex-end;
}

.timeblock-details-header-button {
  height: 22px;
  display: block;
  margin-left: 24px;
  margin-bottom: 19px;
  padding: 0px 12px;
}

.timeblock-details-container h1 {
  font-size: 50px;
  margin-top: 10px;
}

.timeblock-details-container > div > label {
  font-size: 18px;
}

.timeblock-details-title-textarea {
  color: #111;
  width: 100%;
  height: 80px;
  margin-top: 5px;
  font-size: 50px;
  resize: none;
}

.timeblock-details-body-textarea {
  color: #111;
  width: 100%;
  min-height: 200px;
  margin-top: 5px;
  resize: none;
  flex: 1;
  font-size: 18px;
}
