.timeblocks-timeline {
  min-height: 200px;
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

.timeblock {
  background: #f4f4f4;
  border: 5px white solid;
  padding: 10px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  justify-content: right;
  box-sizing: border-box;
}

.timeblock-inprogress {
  border: 5px #444 solid;
}

.timeblock-disabled {
  background: #f9f9f9;
  color: #999;
}

.timeblock-header {
  flex-basis: 1;
  overflow-wrap: anywhere;
}

.timeblock-body {
  padding-top: 22px;
  position: relative;
  height: 100%;
  overflow: hidden;
}

.timeblock-body p{
  font-size: 12px;
  position: absolute;
  text-overflow: ellipsis;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
}

.timeblock-footer {
  text-align: end;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 5px;
}

.timeblock-footer-timecontrol {
  display: flex;
  align-items: center;
}

.timeblock-footer-timecontrol h6 {
  margin: 0px 2px;
  padding-top: 2px;
}